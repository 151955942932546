import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable  } from '@angular/core';
import { AuthService } from 'src/app/auth/shared/services/auth.service';
import { UserService } from 'src/app/auth/shared/services/user.service';


@Injectable()
export class RouteGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router     : Router
    ) {}

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.check(state.url.substring(5));
    }

    canActivate() {
        return this.check();
    }

    private check(url: string | null = null) {
        console.log('url', url);
        
        if(!url){
            this.verificarAccesoHome();
        }

        if (url !== null && url.length > 0) {
            if(url ===  'usuarios/mis-datos') return true;
            return this.authService.puedeNavegar(url);
        }

        return true;

    }

    public async verificarAccesoHome(){
        let usuario = await this.userService.fetchUserAsync()
        let tieneAcceso = usuario.accesos?.some((acceso: any) => acceso.nombre === 'Solicitudes de cobro');
        if(tieneAcceso){
            this.router.navigateByUrl('app/gestion-saldos/solicitudes-cobro');
            return;
        }
        
    }

}
